import React, { useState } from "react";

import Cylinder22 from "../assets/cylinder22.png";
import Logo from "../assets/logo.svg";
import Instagram from "../assets/instagram.png";
import Facebook from "../assets/facebook2.png";
import Twitter from "../assets/twitter.png";
import Modal from "react-modal";

import Timer from "./timer"
Modal.defaultStyles.overlay.backdropFilter = 'blur(.8px)';
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border:"0",
  },
  
};
//Modal.setAppElement("#yourAppElement");

const Content = () => {
  // let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setDate ] = useState({
    firstname:"",
    surname:"",
    phone:"",
    email:""
  })  
  

  function openModal() {
    setIsOpen(true);
  }

  // function afterOpenModal() {
  //   references are now sync'd and can be accessed.
  //   subtitle.style.color = "#f00";
  // }

  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitted");
    alert("Thank you for joining our waitlist..")
    setDate({
      firstname:"",
      surname:"",
      phone:"",
      email:""
    })
    setIsOpen(() => (!modalIsOpen))
  };

  return (
    <div className=" flex flex-col justify-center bg-background bg-no-repeat bg-cover w-full min-h-screen relative ">
      <div className="flex flex-row   my-[5rem] px-[9rem] justify-between md:my-[0rem] md:px-[1rem] sm:px-[1rem] ">
        <div className="flex flex-col  h-[32rem]">
          <div className="md:w-[4rem] sm:w-[4rem]">
            <img src={Logo} alt="" />
          </div>

          <div className="mt-[4rem] md:mt-[3rem] sm:mt-[2rem]">
            <p className="w-[auto] text-[4.5rem] md:text-[1.4rem] sm:text-[1.2rem] text-[#333333] opacity-[0.25] mb-[3rem]">
              GASLEET <br />COMING SOON...
            </p>
            <div className="mb-[3rem]">
            <button
              className="bg-[#5BCCF7] w-[11rem] h-[3.3rem] rounded-xl text-[#FFFFFF] md:w-[7rem] md:mt-[1rem] md:mr-[1rem] sm:w-[5rem] sm:text-[1rem] sm:mt-[1rem] sm:h-[3rem] "
              onClick={openModal}
            >
              Join WaitList
            </button>
            </div>
            {/* <h1 className="w-[35rem] text-[3rem] font-fjalla color-[#333333] md:text-[2rem] md:w-[25rem] sm:text-[1.5rem] sm:w-[20rem]">
              Unlock Seamless Gas Access Anytime, And Anywhere
            </h1> */}
            {/* <p className="w-[33rem] h-[4.8rem] font-lora opacity-[0.8] md:w-[27rem] md:text-[0.9rem] sm:w-[18rem] sm:text-[0.7rem]">
              Your Digital Solution for Convenient Delivery and Smart Management
              that ensures Reliable Deliveries and Prevents Unexpected Gas
              Shortages with Smart Monitoring
            </p> */}
          </div>
          <div className="flex justify-between sm:hidden">
            <div className="sm:mx-[1rem] ">
              {/* <Counter /> */}
              <Timer />
            </div>
           
          </div>
          <div className="flex flex-row mt-[2rem] gap-20 md:gap-5 sm:gap-3 text-[#352B26] ">
            <div className="w-[2rem]">
              <img src={Instagram} alt="" />
            </div>
            <div className="w-[2rem]">
              <img src={Facebook} alt="" />
            </div>
            <div className="w-[2rem]">
              <img src={Twitter} alt="" />
            </div>
          </div>
        </div>
        <div>
          <img
            src={Cylinder22}
            alt="Cylinder"
            className="w-[30rem] md:w-[30rem] md:mt-[2rem]"
          />
        </div>
      </div>

      <div className="">
        <Modal
          isOpen={modalIsOpen}
          //onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel=""
        >
          <div className="p-8 h-[70vh] w-[400px]  rounded-md">
            <div>
              <p className="w-[7.3rem] h-[2.3rem] font-fjalla font-normal">
                Join WaitList
              </p>
            </div>

            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="" className="text-[14px]">
                  firstname
                </label>
                <input
                  type="text"
                  name="firstname"
                  placeholder="Enter here"
                  required
                  onChange={e => setDate({...data, firstname: e.target.value})}
                  className="h-[40px] p-2 w-[100%] bg-[#D9D9D9] rounded-[8px] border-0"
                />
              </div>

              <div>
                <label htmlFor="" className="text-[14px]">
                  surname
                </label>
                <input
                  type="text"
                  name="surname"
                  required
                  placeholder="Enter here"
                  onChange={e => setDate({...data, surname: e.target.value})}
                  className="h-[40px] p-2 w-[100%] bg-[#D9D9D9] rounded-[8px] border-0"
                />
              </div>

              <div>
                <label htmlFor="" className="text-[14px]">
                  phone-no
                </label>
                <input
                  type="text"
                  name="phone"
                  required
                  placeholder="Enter here"
                  onChange={e => setDate({...data, phone: e.target.value})}
                  className="h-[40px] p-2 w-[100%] bg-[#D9D9D9] rounded-[8px] border-0"
                />
              </div>

              <div>
                <label htmlFor="" className="text-[14px]">
                  email
                </label>
                <input
                  type="text"
                  name="email"
                  required
                  placeholder="Enter here"
                  onChange={e => setDate({...data, email: e.target.value})}
                  className="h-[40px] p-2 w-[100%] bg-[#D9D9D9] rounded-[8px] border-0"
                />
              </div>

              <div className="self-end" >
                <input type="submit" value="Join" className="bg-[#5BCCF7] w-[8rem] h-[2rem] rounded-md text-[#FFFFFF] font-fjalla" />
                  
           
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Content;
