import React, { Component } from "react";
// import moment from 'moment';


class CountDown extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: ""
    };
    this.x = null;
    this.deadline = null;
  }
  count() {
    const now = new Date().getTime();
    const t = this.deadline - now;
    const dd = Math.floor(t / (1000 * 60 * 60 * 24));
    const hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const ss = Math.floor((t % (1000 * 60)) / 1000);

    const days = dd < 10 ? "0" + dd : dd;
    const hours = hh < 10 ? "0" + hh : hh;
    const minutes = mm < 10 ? "0" + mm : mm;
    const seconds = ss < 10 ? "0" + ss : ss;

    this.setState({ days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: "TIME IS UP"
      });
    }
  }
  componentDidMount() {
    this.deadline = new Date("Jan 31, 2024 21:00:00").getTime();

    this.x = setInterval(this.count, 1000);
  }

  render() {
    const { days, seconds, hours, minutes } = this.state;
    return (
      <div id="" className="flex  center">
        <div className="py-10 pr-10">
          <div className="box">
            <p id="day" style={{ fontSize: "20px", fontWeight: "bold", margin: "0"}}>{days}</p>
            <span className="text" style={{ fontSize: "12px", fontFamily: "sans-serif"}}>Days</span>
          </div>
        </div>
        <div className="p-10">
          <div className="box">
            <p id="hour" style={{ fontSize: "20px", fontWeight: "bold", margin: "0"}}>{hours}</p>
            <span className="text" style={{ fontSize: "12px", fontFamily: "sans-serif"}}>Hours</span>
          </div>
        </div>
        <div className="p-10">
          <div className="box">
            <p id="minute" style={{ fontSize: "20px", fontWeight: "bold", margin: "0"}}>{minutes}</p>
            <span className="text" style={{ fontSize: "12px", fontFamily: "sans-serif"}}>Minutes</span>
          </div>
        </div>
        <div className="p-10">
          <div className="box">
            <p id="second" style={{ fontSize: "20px", fontWeight: "bold", margin: "0"}}>{seconds}</p>
            <span className="text" style={{ fontSize: "12px", fontFamily: "sans-serif"}}>Seconds</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CountDown;
